.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.modal-content {
    border-radius: var(--border-radius-window);
    position: relative;
}
  
.modal-close-button {
    position: fixed;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 3rem;
    color: white;
}

