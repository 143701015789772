.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-fallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--color-background);
  color: var(--color-text);
  padding: 20px;
  text-align: center;
}

.error-fallback h1 {
  color: var(--color-red);
  font-size: 2rem;
  margin-bottom: 10px;
}

.error-fallback p {
  color: var(--color-text);
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.error-fallback-button {
  background-color: var(--background-default-button);
  color: var(--color-default-button);
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.1s;
  cursor: pointer;
}

.error-fallback-button:hover {
  background-color: var(--background-default-button-hover);
  transform: scale(1.05);
}

.error-fallback-button:focus {
  outline: none;
  box-shadow: 0 0 5px var(--color-yellow);
}
