.settings-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.settings {
    background-color: var(--background-default);
    color: var(--color-default);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.settings form {
    text-align: left;
    font-size: var(--font-size-text);
}

.settings form div {
    margin: 20px;
}

.settings form select {
    color: var(--color-default-button);
	background-color: var(--background-default-button);
	padding: 6px 12px;
    width: 100px;
	border-radius: var(--border-radius-button);
	border: none;
	outline: none;
    float: right;
    margin-left: 20px;
}

.settings form select:hover {
	cursor: pointer;
}

@media (max-width: 480px) {
    
    .settings {
        width: 100vw;
    }

    .settings form div {
        margin: 20px 40px;
    }

}