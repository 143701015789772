.element-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border: var(--border-width-table-container) var(--border-style-table-container) var(--border-color-table-container);
    background-color: var(--background-table-container);
    max-width: 600px;
    margin: 0 auto;
    font-size: var(--font-size-text);
}

.field {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.field strong {
    font-weight: bold;
    color: var(--color-table-header);
}

.field span {
    color: var(--color-table-row);
    word-wrap: break-word;
}

.value {
    text-align: left;
    width: 100%;
}