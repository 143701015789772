button {
	transition: background-color 0.3s;
}

.sheet {
	display: inline-grid;
	background: var(--background-sheet);
	border: var(--border-sheet);
	border-radius: var(--border-radius-sheet);
	grid-template-columns: repeat(6, var(--width-box));
	margin: var(--margin-sheet);
	box-shadow: 0px 12px 24px rgba(70, 70, 70, 0.2);
	transition: box-shadow 0.3s ease;
}

.sheet:hover {
	box-shadow: 0px 12px 24px rgba(70, 70, 70, 0.5);
}

.column {
	display: inline-grid;
	grid-template-rows: repeat(17, var(--height-box));
}

.settings-button-sheet {
	background: var(--background-settings-button);
	color: var(--color-settings-button);
	border: var(--border-settings-button);
	border-radius: var(--border-radius-box);
}

.settings-button-sheet img {
	width: calc(var(--width-box) / 2);
	filter: var(--filter-settings-button)
}

.roll-button {
	background: var(--background-roll-button);
	border: var(--border-roll-button);
	border-radius: var(--border-radius-box);
	grid-row-start: 2;
	grid-row-end: 8;
}

.roll-button:hover:enabled {
	background-color: var(--background-roll-button-hover);
}

.roll-button img {
	filter: var(--filter-roll-button);
}

.top-section-sum {
	grid-row-start: 8;
}

.bottom-section-corner {
	grid-row-start: 12;
	grid-row-end: 17;
	width: var(--width-box);
	border-radius: var(--border-radius-box);
}

.restart-button {
	height: 100%;
	width: 100%;
	background: var(--background-restart-button);
	border: var(--border-restart-button);
}

.reaction-buttons {
	height: 100%;
	background: var(--background-restart-button);
	border: var(--border-restart-button);
}

.reaction-button {
	font-size: var(--font-size-tab);
	height: 33%;
	width: 100%;
	background: var(--background-restart-button);
	border: var(--border-restart-button);
}

.reaction-button:hover {
	background: var(--background-restart-button-hover);
	border: var(--border-restart-button-hover);
}

.sheet .notification-button {
	position: relative;
	width: var(--width-box);
	background-color: var(--background-restart-button);
	border: var(--border-restart-button);
	border-radius: var(--border-radius-box);
	transition: background-color 0.3s ease;
}

.sheet .notification-button .icon {
    filter: grayscale(100%);
    transition: filter 0.3s ease;
}
  
.sheet .notification-button:hover:enabled {
	background-color: var(--background-restart-button-hover);
}

.sheet .notification-button:hover:enabled .icon {
	filter: grayscale(0%);
}

.sheet .notification-badge {
	position: absolute;
	top: 6px;
	right: 10px;
	background-color: var(--color-red);
	color: white;
	border-radius: 50%;
	padding: 0;
	font-size: 0.55rem;
	font-weight: bold;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 20px;
	height: 20px;
}

.middle-section-corner {
	grid-row-start: 9;
	grid-row-end: 11;
	width: var(--width-box);
	border-radius: var(--border-radius-box);
}

.undo-button {
	color: var(--color-default);
	height: 100%;
	width: 100%;
	background: var(--background-restart-button);
	border: var(--border-restart-button);
	border-radius: var(--border-radius-box);
	font-size: var(--font-size-banner);
	transition: filter 0.3s ease, background-color 0.3s ease;
}

.undo-button:hover:enabled {	
	background-color: var(--background-restart-button-hover);
	filter: grayscale(0%);
}

.restart-button:hover:enabled {
	background-color: var(--background-restart-button-hover);
}

.restart-button img {
	filter: var(--filter-restart-button);
}

.middle-section-sum {
	grid-row-start: 11;
}

.bottom-section-sum {
	grid-row-start: 17;
}

.last-row {
	display: inline-grid;
	grid-template-columns: repeat(3, calc(2*var(--width-box)));
	width: calc(6 * var(--width-box));
	height: var(--height-box);
}

.username-button {
	font-weight: bold;
	font-size: var(--font-size-text);
	color: var(--color-username-button);
	border: var(--border-username-button);
	background: var(--background-username-button);
}

.sheet .icon {
	font-size: var(--font-size-title);
	color: var(--color-label);
}

.switch-container {
	width: 100%;
	background: var(--background-username-button);
}

.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked+.slider {
	background-color: #2196F3;
}

input:focus+.slider {
	box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.username-input {
	border-radius: var(--border-radius-button);
	padding: 14px;
	background-color: var(--background-default-input);
	color: var(--color-default-input);
	font-size: var(--font-size-tab);
}