.player-list-container, .player-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.player, .player-list {
    min-width: 200px;
    width: 70%;
    max-width: 1000px;
    min-height: 400px;
    height: 80vh;
    overflow-y: auto;
}

.player-icon {
    display: block;
    margin: 10px;
    border: none;
    background-color: none;
}

.player-icon:hover:enabled {
    cursor: pointer;
    opacity: 70%;
}

.player-icon .icon {
    color: var(--background-save-button);
}

.player-icon .avatar {
    border-radius: 25px;
    margin: 5px;
    width: 50px;
    height: 50px;
    transition: outline 0.1s ease-in-out;
}

.player-icon .avatar.accepted {
    outline: 3px solid var(--background-save-button);
}

.player-icon .avatar.declined {
    outline: 3px solid var(--background-delete-button);
}

.player-icon .avatar.selected {
    outline: 3px solid var(--background-edit-button);
    opacity: 100%;
}

.player-icon .avatar.not-selected {
    opacity: 50%;
}

.player-icon .avatar.offline {
    opacity: 50%;
}

.player-name {
    font-size: var(--font-size-text);
    color: var(--color-default);
}

.player h3,
.player-list h3 {
    font-size: var(--font-size-tab);
    font-weight: normal;
    margin: 10px;
    color: var(--color-default);
}

@media (max-width: 480px) {

    .player, .player-list {
        width: 100vw;
        margin-top: 40px;
        height: 90vh;
    }
    
}