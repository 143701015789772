.spinner-container {
    padding-top: 30vh;
    overflow: hidden;
}

.spinner {
    font-size: 4rem;
    color: var(--color-default);
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}