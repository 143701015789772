.auth-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.auth {
    background-color: var(--background-auth);
    padding: 20px 30px;
    border-radius: var(--border-radius-window);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 312px;
    color: var(--color-auth);
}

.auth h2 {
    margin-bottom: 20px;
    font-size: var(--font-size-title);
    text-align: center;
    font-weight: normal;
}

.auth input[type="text"],
.auth input[type="email"],
.auth input[type="password"] {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    box-sizing: border-box;
    font-size: var(--font-size-text);
    background-color: var(--background-auth-input);
    color: var(--color-auth-input);
    border-bottom: 1px solid var(--border-auth-input);
    border-radius: var(--border-radius-button);
    caret-color: var(--color-auth-input);
}

.auth input[type="text"]:-webkit-autofill,
.auth input[type="text"]:-webkit-autofill:hover, 
.auth input[type="text"]:-webkit-autofill:focus, 
.auth input[type="text"]:-webkit-autofill:active,
.auth input[type="email"]:-webkit-autofill,
.auth input[type="email"]:-webkit-autofill:hover, 
.auth input[type="email"]:-webkit-autofill:focus, 
.auth input[type="email"]:-webkit-autofill:active,
.auth input[type="password"]:-webkit-autofill,
.auth input[type="password"]:-webkit-autofill:hover, 
.auth input[type="password"]:-webkit-autofill:focus, 
.auth input[type="password"]:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--color-auth-input);
    box-shadow: inset 0 0 20px 20px var(--background-auth-input);
}

.auth input[type="submit"], .button-send-password-reset-email {
    width: 100%;
    padding: 12px;
    background-color: var(--background-auth-button);
    border: none;
    color: var(--color-auth-button);
    font-size: var(--font-size-text);
    transition: background-color 0.3s ease-in-out;
    border: 1px solid var(--border-auth-button);
    border-radius: var(--border-radius-button);
    margin-top: 10px;
}

.auth input[type="submit"]:hover:enabled, .button-send-password-reset-email:hover:enabled {
    cursor: pointer;
    background-color: var(--background-auth-button-hover);
}

.auth input[type="submit"]:disabled, .button-send-password-reset-email:disabled {
    background-color: var(--background-auth-button-disabled);
}

.check-email {
    font-size: var(--font-size-text);
}

.auth .link {
    margin-top: 15px;
    text-align: center;
    font-size: var(--font-size-text);
}

.auth .link a:hover {
    cursor: pointer;
    text-decoration: underline;
}

.auth img {
    width: 64px;
    margin-top: 20px;
}

.auth .input-label {
    font-size: var(--font-size-text);
    width: 100%;
    display: flex;
}

.auth img:hover {
    outline: none !important;
}

.error-text {
    color: var(--color-red);
    font-size: var(--font-size-text);
    margin-top: 5px;
    display: block;
}

.forgot-password {
    font-size: var(--font-size-text);
}

.recaptcha-branding {
    margin-top: 15px;
    font-size: var(--font-size-text);
    color: #666;
    text-align: center;
    line-height: 1.5;
}

.recaptcha-branding a {
    color: #007bff;
    text-decoration: none;
}

.recaptcha-branding a:hover {
    text-decoration: underline;
}


@media screen and (max-width: 480px) {

    .auth {
        max-width: 100vw;
        width: 100vw;
    }

}