.collapsible-section {
    border: var(--border-width-table-container) var(--border-style-table-container) var(--border-color-table-container);
    border-radius: var(--border-radius-button);
    font-size: var(--font-size-text);
    background-color: var(--background-table-container);
}

.collapsible-header {
    display: flex;
    justify-content: space-between;
    background-color: var(--background-table-header);
    cursor: pointer;
    color: var(--color-table-header);
}

.collapsible-header:hover {
    background-color: var(--background-table-header-hover);
    color: var(--color-table-header-hover);
}

.collapsible-header span {
    padding: 14px;
}

.collapsible-content {
    padding: 10px;
    background-color: var(--background-table-row);
    color: var(--color-table-row);
}

@media (max-width: 480px) {
    .collapsible-content {
        padding: 0;
    }
}
