.profile-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: var(--color-default);
}

.profile.id-card {
    background-color: var(--background-auth);
    border-radius: var(--border-radius-window);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    padding-bottom: 10px;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.id-card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.avatar-container {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #ddd;
    position: relative;
    margin-right: 10px;
}

.id-card-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.upload-label {
    position: absolute;
    opacity: 0%;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--background-default-button);
    color: var(--color-default-button);
    padding: 5px 10px;
    font-size: var(--font-size-text);
    border-radius: var(--border-radius-window);
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.avatar-upload-input {
    display: none;
}

.avatar-container:hover .upload-label {
    opacity: 100%;
}

.username-container {
    flex: 1;
}

.input-group {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
}

.input-group label {
    font-size: var(--font-size-text);
    text-align: left;
}

.id-card-input {
    padding: 10px;
    border: 1px solid var(--background-auth);
    border-radius: var(--border-radius-button);
    font-size: var(--font-size-text);
    outline: none;
    transition: border-color 0.3s ease;
    color: var(--color-auth);
    background-color: var(--background-auth);
}

.id-card-input:enabled {
    border-color: var(--color-auth);
}

.error-text {
    font-size: var(--font-size-text);
    color: #d9534f;
}

.id-card-body {
    display: flex;
    flex-direction: column;
}

.save-button,
.edit-button,
.cancel-button {
    margin-top: 10px;
    padding: 10px;
    font-size: var(--font-size-text);
    border: none;
    border-radius: var(--border-radius-button);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.save-button {
    background-color: var(--background-save-button);
    color: var(--color-auth-button);
}

.save-button:hover {
    background-color: var(--background-save-button-hover);
}

.edit-button {
    background-color: var(--background-edit-button);
    color: var(--color-edit-button);
}

.edit-button:hover {
    background-color: var(--background-edit-button-hover);
}

.cancel-button {
    background-color: var(--background-delete-button);
    color: var(--color-delete-button);
}

.cancel-button:hover {
    background-color: var(--background-delete-button-hover);
}

.button-resend-verification-email {
    background-color: var(--background-auth-button);
    color: var(--color-auth-button);
    padding: 10px;
    font-size: var(--font-size-text);
    border: none;
    border-radius: var(--border-radius-button);
    transition: background-color 0.3s ease;
    width: 100%;
    margin-top: 10px;
}

.reset-link {
    margin-top: 10px;
}

.button-resend-verification-email:hover:enabled {
    background-color: var(--background-auth-button-hover);
}

.verified {
    font-size: var(--font-size-text);
    text-align: right;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    background: white;
    padding: 20px;
    padding-top: 0;
    border-radius: var(--border-radius-window);
    width: 400px;
    max-width: 90%;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}