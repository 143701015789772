.admin-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.admin {
    background-color: var(--background-default);
    color: var(--color-default);
    padding: 20px 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.admin a {
    display: flex;
    margin: 20px;
    text-align: center;
}

.admin a:hover {
    text-decoration: none;
    color: var(--color-default-button-hover);
}

@media (max-width: 480px) {

    .admin {
        width: 100vw;
    }

}