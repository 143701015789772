.about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
}

.about {
    padding: 20px;
    background-color: var(--background-default);
    color: var(--color-default);
    border-radius: var(--border-radius-window);
    min-width: 300px;
    max-width: 600px;
    width: 60%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
    font-size: var(--font-size-text);
    overflow: hidden;
}

.about section {
    overflow-y: auto;
    padding: 10px;
}

.about ul {
    text-align: left;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 20px;
}

.button-refresh {
    width: 100%;
    padding: 12px;
    background-color: var(--background-default-button);
    border: none;
    color: var(--color-default-button);
    font-size: var(--font-size-text);
    transition: background-color 0.3s ease-in-out;
    border: 1px solid var(--border-default-button);
    border-radius: var(--border-radius-button);
    margin-top: 20px;
    margin-bottom: 10px;
}

.button-refresh:hover:enabled {
    cursor: pointer;
    background-color: var(--background-auth-button-hover);
}

.button-refresh:hover:enabled {
    cursor: pointer;
    background-color: var(--background-default-button-hover);
}

.button-refresh:disabled {
    background-color: var(--background-default-button-disabled);
}

.section-buttons button {
    color: var(--color-default);
    padding: 10px;
    margin: 4px;
    border: none;
    transition: background-color 0.3 ease;
    border-radius: var(--border-radius-button);
}

.section-buttons button:hover {
    background-color: var(--background-default-hover);
}

.section-buttons button.active {
    background-color: var(--background-default-hover);
}

.about p {
    text-align: center;
    margin: 0;
    padding: 0;
}

.about .icon {
    filter: grayscale(100%);
    font-size: var(--font-size-text);
}

.about section {
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
}

.about form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 20px;
}

.about form div {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.about form label {
    color: var(--color-default);
    margin-bottom: 4px;
}

.about form input[type="text"],
.about form textarea {
    padding: 10px;
    border: 1px solid gray;
    border-radius: var(--border-radius-button);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    width: 100%;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

.about form input[type="text"]:focus,
.about form textarea:focus {
    border: 1px solid gray;
    outline: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.about form textarea {
    resize: vertical;
    min-height: 100px;
    max-height: 140px;
}

.about form button[type="submit"] {
    width: 100%;
    padding: 12px;
    background-color: var(--background-default-button);
    border: none;
    color: var(--color-default-button);
    font-size: var(--font-size-text);
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.1s;
    border-radius: var(--border-radius-button);
    margin-top: 15px;
}

.about form button[type="submit"]:hover {
    background-color: var(--background-default-button-hover);
}

@media screen and (max-width: 480px) {

    .about {
        width: 100vw;
        padding: 20px 0;
    }

    .about section {
        height: auto;
        max-height: 550px;
    }

}
