.notification-list-container {
    background-color: var(--background-default);
    padding: 20px;
    border-radius: var(--border-radius-window);    
    font-size: var(--font-size-text);
    color: var(--color-default);
}

.notification-list {
    list-style: none;
    max-height: 300px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
}
  
.notification-item {
    border-bottom: 1px solid #ccc;
    margin: 10px;
    width: 300px;
}
  
.notification-item:last-child {
    border-bottom: none;
}

.notifications p {
    font-size: var(--font-size-text);
}

.mark-as-read-button,
.mark-all-as-read-button {
    display: inline-block;
    border: none;
    border-radius: var(--border-radius-button);
    font-size: var(--font-size-button);
    cursor: pointer;
}

.mark-all-as-read-button {
    background-color: var(--background-default-button);
    color: var(--color-default-button);
    padding: 10px;
}

.mark-as-read-button {
    background-color: var(--background-delete-button);
    color: var(--color-delete-button);
    margin: 5px;
    margin-left: 10px;
    padding: 5px 10px;
}

.mark-as-read-button:hover {
    background-color: var(--background-delete-button-hover);
}
  
@media (max-width: 480px) {

    .notification-list-container {
        background-color: var(--background-default);
        padding: 20px;
    }

    .mark-all-as-read-button {
        width: 100%;
    }

}